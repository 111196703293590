import Deck from './Deck';
import Animation from './Animation';

export default function Flora() {
    const lines = [];
    for (let i = 0; i < 1; i++) {
        lines.push(<p key={i}>I like things that grow.</p>)
    }

    return (
        <section>
            <Deck>
                {lines}
            </Deck>
            <Animation></Animation>
        </section>
        
    );
}