import React, {useState, useEffect, useRef, useCallback} from 'react';

function Moment({player, muted}) {
    const videoRef = useCallback(node => {
        if(node && player) {
            player.node = node;
        }
    }, []);

    return (
        <div id="moment">
            <video ref={ videoRef } loop={true} muted={muted} autoPlay={true}>
            </video>
        </div>
    );
}

export default Moment;