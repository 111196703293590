class MomentService {
    cursor = 0;
    paths = [];

    get endpoint() {
        return new URL('https://069w44i5k7.execute-api.us-east-1.amazonaws.com/');
    }

    async getAwsVideos() {
        const url = this.endpoint;
        const response = await fetch(url);
        const data = await response.json();
        return data.Items.map(i=>i.url);
    }

    async fetchVideos() {
        const videos = await this.getAwsVideos();
        return videos;  //this.getAwsVideos();
    }

    async loadVideos() {
        var paths = await this.fetchVideos();
        this.paths = this.shuffle(paths);
    }

    next() {
        this.cursor++;
        if(this.cursor >= this.paths.length) this.cursor = 0;
    }

    get current() {
        let current = this.paths[this.cursor || 0];
        return current;
    }

    shuffle(list) {
        var array = list.slice(0);
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Create a random index to pick from the original array
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // Cache the value, and swap it with the current element
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }
}

export { MomentService }