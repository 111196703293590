import React from 'react';
import { NavLink, Link } from "react-router-dom";

import { withRouter } from 'react-router';
import VolumeOff from '../assets/vectors/VolumeOff.svg';
import VolumeOn from '../assets/vectors/VolumeOn.svg';
import Next from '../assets/vectors/Next.svg';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleAudioToggle = this.handleAudioToggle.bind(this);
    }
  
    handleRefresh(e) {
        this.props.onMomentRefresh(e.target.value);
    }

    handleAudioToggle(e) {
        this.props.onAudioToggle(e.target.value);
    }
  
    render() {
        const videoControlsDisabled = (this.props.location||{}).pathname !== '/';

        return (
            <header className="section-padding">
                <Link id="brand" to="/">
                    habit
                </Link>
                <nav>
                    <button disabled={videoControlsDisabled} onClick={this.handleRefresh}>
                        <Next/>
                    </button>
                    <button disabled={videoControlsDisabled} onClick={this.handleAudioToggle}>
                        {this.props.muted ? <VolumeOff/> : <VolumeOn/>}
                    </button>
                    <NavLink exact to="/">Intro</NavLink>
                    <NavLink to="/flora">Flora</NavLink>
                </nav>
            </header>
        );
    }
}

export default withRouter(Header);