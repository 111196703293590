import './App.css';

import Header from './components/Header'
import Definition from './components/Definition'
import Flora from './components/Flora'

import { MomentService } from './services/MomentService'
import Moment from './components/Moment'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import {useState, useEffect} from 'react';
import { MomentPlayer } from './services/MomentPlayer';

export default function App() {
    const [momentSrc, setMomentSrc] = useState(null);
    const [muted, setMuted] = useState(true);

    const [player,setPlayer] = useState(new MomentPlayer());
    const [moments, setMoments] = useState();

    useEffect(() => {
        let ignore = false;

        async function set() {
            if(!ignore) {
                const _moments = new MomentService();
                await _moments.loadVideos();
                setMoments(_moments);
            }
        }

        set();
        return () => { ignore = true; }
    }, [])

    useEffect(()=> {
        if(moments) {
            setMomentSrc(moments.current);
        }
    },[moments])

    useEffect(()=>{
        if(player && momentSrc) {
            player.source = momentSrc;
        }
    }, [momentSrc, player])


    function handleAudioToggle() {
        setMuted(!muted);
    }

    function handleMomentRefresh() {
        moments.next();
        setMomentSrc(moments.current);
    }

    return (
        <Router>
            <main>
                <Header onMomentRefresh={handleMomentRefresh} onAudioToggle={handleAudioToggle} muted={muted}></Header>
                <section id="content">
                    <Switch>
                        <Route path="/flora">
                            <Flora></Flora>
                        </Route>
                        <Route path="/">
                            <Moment player={player} muted={muted}></Moment>
                        </Route>
                    </Switch>
                    <Definition></Definition>
                </section>
            </main>
        </Router>
    );
}