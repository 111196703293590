import { Delaunay } from 'd3-delaunay';
import React from "react";
import * as d3 from 'd3'

function useWindowSize(initial, debounceMs = 1000) {
    const isSSR = typeof window !== "undefined";
    const [windowSize, setWindowSize] = React.useState(initial || {
        width: isSSR ? 1200 : window.innerWidth,
        height: isSSR ? 800 : window.innerHeight,
    });

    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowSize({
              height: window.innerHeight,
              width: window.innerWidth
            })
        }, debounceMs)

        window.addEventListener("resize", debouncedHandleResize);

        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    return windowSize;
}

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

export default function Animation() {
    const size = useWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
    }, 50);

    const contextRef = React.useRef(null);

    

    const [particles, setParticles] = React.useState();

    const density = 10;
    const fps = 60;

    React.useEffect(() => {
        let count = (size.width + size.height) / 100 * density;
        setParticles(Array.from({ length: count }, () => [Math.random() * size.width, Math.random() * size.height, 0, 0]));
    }, [size])

    const requestRef = React.useRef()
    const previousTimeRef = React.useRef();

    const animate = time => {
        let now = Date.now();

        if (previousTimeRef.current == undefined) {
            previousTimeRef.current = now;
        } else {
            const newParticles = (ps) => ps.map((item, i) => {
                const p = item;
                //const p = [...item];
                p[0] += p[2];
                p[1] += p[3];
                p[2] += 0.2 * (Math.random() - 0.5) - 0.05 * p[2];
                p[3] += 0.2 * (Math.random() - 0.5) - 0.05 * p[3];
                return p;
            });


            let then = previousTimeRef.current,
                elapsed = now - then;

            if (elapsed > (1000 / fps)) {
                previousTimeRef.current = now;
                setParticles(newParticles);
            }
        }
        requestRef.current = requestAnimationFrame(animate);
    }

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once

    React.useEffect(function () {
        if (!particles) return;

        const canvas = d3.select(contextRef);
        const context = canvas.node().current.getContext('2d');
        context.canvas.style.background = "transparent";//"#fff";
        context.strokeStyle = "rgba(255,255,255,.2)";
        // context.fillStyle = context.strokeStyle;
        context.clearRect(0, 0, size.width, size.height);

        const delaunay = Delaunay.from(particles);
        const voronoi = delaunay.voronoi([0, 0, size.width, size.height]);
        context.save();


        context.beginPath();
        // delaunay.renderPoints(context);
        context.fill();
        context.beginPath();
        voronoi.render(context);
        context.stroke();
    }, [particles])

    return <canvas id="animation" ref={contextRef} width={size.width} height={size.height} />;
}