import Hls from "hls.js";

class MomentPlayer {
    hls;
    manifestUrl;

    constructor() {
        this.hls = new Hls();
    }

    get node() {
        return this.hls.media;
    }

    set node(node) {
        if(this.node && this.node !== node) {
            this.hls.detachMedia();
        }
        this.hls.attachMedia(node);
    }


    get source() {
        return this.manifestUrl;
    }

    set source(source) {
        if(this.manifestUrl != source) {
            let node = this.node;
            this.dispose();
            this.hls = new Hls();
            this.hls.loadSource(source);
            this.hls.attachMedia(node);

        }

        this.manifestUrl = source;
    }

    dispose() {
        this.hls.stopLoad();
        this.hls.destroy();
    }
}

export {MomentPlayer};